@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
}

:root {
  --blue: #0074c2;
}

html {
  scroll-behavior: smooth;
  overflow-y: scroll;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #000715;
  overflow-x: hidden;
  box-sizing: border-box !important;
}

a {
  color: var(--blue);
  text-decoration: none;
}

p {
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: normal;
  line-height: 1.8em;
}

.block {
  width: 100%;
  padding: 0 25px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}

.container {
  width: 100%;
  max-width: 1200px;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  /* margin-top: 35px; */
  z-index: 99;
  box-sizing: border-box;
  /* position: absolute; */
  justify-content: center;
}

.header img {
  width: 140px;
}

.header a {
  display: inline-block;
  display: flex;
  align-items: center;
}

.header span {
  margin: 0 10px;
}

.header h2 {
  display: inline-block;
  margin: 0;
  font-size: 32px;
}

.main-ctn {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-ctn {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 99;
}

.input-ctn p {
  text-align: center;
  max-width: 300px;
  margin: 30px 0;
}

.input-field-ctn {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}

input {
  padding: 15px 10px;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  border: 2px solid #0074c2;
  transition: all ease-in-out 100ms;
  flex-grow: 2;
}

input:focus,
input:focus-visible {
  outline: none;
  background-color: rgba(0, 217, 255, 0.15);
  border-color: #0084dd;
}

.suffix {
  background-color: #0074c2;
  border: 2px solid #0074c2;
  border-left: none;
  padding: 0 10px;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
}

.submit-btn {
  width: 100%;
  max-width: 300px;
  background-color: rgba(252, 252, 252, 0.3);
  color: rgb(177, 177, 177);
  font-size: 16px;
  font-weight: 600;
  clip-path: polygon(100% 0, 100% 25px, calc(100% - 20px) 100%, 0 100%, 0 0);
  text-align: center;
  cursor: pointer;
  padding: 10px 0;
}

.btn-active {
  background-color: #0074c2;
  color: #fff;
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  margin: 0;
  padding: 0;
}

.landingbackground-image {
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -7;
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: center;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  background: rgba(0, 0, 0, 0.6);
}

.landingbackground-image2 {
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -7;
  width: 100%;
  height: 100vh;
  min-height: 900px;
  position: absolute;
  background-position: center;
  top: 0;
  left: 0;
}

.overlay2 {
  width: 100%;
  height: 100vh;
  min-height: 900px;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
}

.animal-ctn {
  width: 100%;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.animal-background-ctn {
  width: 100%;
  max-width: 700px;
  margin-bottom: -20px;
}

.animal-img {
  width: 100%;
}

.animal-background {
  width: 100%;
  border-radius: 20px;
}

.animal-header {
  font-size: 180px;
  color: #fff;
  font-weight: 600;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  margin: 0 auto;
  line-height: 0.7;
  display: inline-block;
  text-align: center;
}

.text-ctn {
  width: 100%;
  text-align: center;
  z-index: 999;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text-ctn h2 {
  font-size: 33px;
  margin: 0;
  border-top: 1px solid #00abe7;
  border-bottom: 1px solid #00abe7;
  padding: 15px 0;
  margin-bottom: 15px;
}

.text-ctn h2 span {
  color: #00abe7;
}

.description {
  padding: 0 15px;
}

.home-btn {
  width: 100%;
  max-width: 200px;
  background-color: #00abe7;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  clip-path: polygon(100% 0, 100% 25px, calc(100% - 20px) 100%, 0 100%, 0 0);
  text-align: center;
  cursor: pointer;
  padding: 10px 0;
  margin-top: 20px;
}

.bar-section-ctn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0 20px;
  flex-direction: column;
  padding: 0 10px;
  box-sizing: border-box;
}

.bar-ctn {
  display: flex;
  align-items: center;
  width: 100%;
}

.level-ctn {
  display: flex;
  width: 90px;
  align-items: center;
  justify-content: flex-start;
}

.level-ctn:last-child {
  justify-content: flex-end;
}

.level-ctn img {
  width: 40px;
  margin-right: 10px;
  /* filter: invert(50%) sepia(89%) saturate(2504%) hue-rotate(163deg)
    brightness(98%) contrast(101%); */
}

.level-ctn:last-child img {
  margin-right: 0px;
  margin-left: 10px;
}

.level {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.05em;
}

.outer-bar {
  width: calc(100% - 180px);
  height: 4px;
  position: relative;
  border-radius: 50px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 12px 3px rgba(0, 169, 231, 0.9);
  -moz-box-shadow: 0px 0px 12px 3px rgba(0, 169, 231, 0.9);
  box-shadow: 0px 0px 12px 3px rgba(0, 169, 231, 0.9);
}

.inner-bar {
  height: 4px;
  background-color: #00a9e7;
  position: absolute;
  border-radius: 50px;
}

.amount {
  font-weight: 600;
  font-size: 18px;
}

/************************************************ GLITCH *****************************************************************/

:root {
  --f-size: 15;
  --f-unit: 1vmin;
  --f: calc(var(--f-size) * var(--f-unit));
  --bg: #001137;
}

.glitch {
  flex: 1;
  line-height: 0.75;
  margin: auto 10px;
  color: #1af0dc;
  color: #0074c2;
  color: #fff;
  text-align: center;
  transform: scaleX(var(--scale, 1));
  animation: glitch-p 11s infinite alternate;
}
.glitch::before,
.glitch::after {
  --top: 0;
  --left: 0;
  --v-height: 30%;
  --n-tenth: calc(var(--f-size) * 0.1 * var(--top));
  --t-cut: calc(var(--n-tenth) / var(--f-size) * 100%);
  --b-cut: calc(var(--t-cut) + var(--v-height));
  content: attr(data-text);
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  transform: translateX(calc(var(--left) * 100%));
  filter: drop-shadow(0 0 transparent);
  text-shadow: calc(var(--left) * -3em) 0 0.02em lime,
    calc(var(--left) * -6em) 0 0.02em #ff00e1;
  background-color: var(--bg);
  clip-path: polygon(
    0% var(--t-cut),
    100% var(--t-cut),
    100% var(--b-cut),
    0% var(--b-cut)
  );
}
.glitch::before {
  animation: glitch-b 1.7s infinite alternate-reverse;
}
.glitch::after {
  animation: glitch-a 3.1s infinite alternate;
}
@keyframes glitch-p {
  17% {
    --scale: 0.87;
  }
  31% {
    --scale: 1.1;
  }
  37% {
    --scale: 1;
  }
  47% {
    --scale: 0.91;
  }
  87% {
    --scale: 1;
  }
}
@keyframes glitch-a {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
  }
  20% {
    --left: 0.005;
  }
  40% {
    --left: 0.01;
    --v-height: 20%;
    --top: 3;
  }
  60% {
    --left: 0.03;
    --v-height: 25%;
    --top: 6;
  }
  80% {
    --left: 0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: 0.083;
    --v-height: 30%;
    --top: 1;
  }
}
@keyframes glitch-b {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
    --top: 10;
  }
  20% {
    --left: -0.005;
  }
  40% {
    --left: -0.01;
    --v-height: 17%;
    --top: 3;
  }
  60% {
    --left: -0.03;
    --v-height: 35%;
    --top: 6;
  }
  80% {
    --left: -0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: -0.083;
    --v-height: 30%;
    --top: 1;
  }
}

@media (max-width: 950px) {
  .glitch {
    animation: none;
  }

  .glitch::before {
    animation: none;
  }
  .glitch::after {
    animation: none;
  }

  .glitch::before,
  .glitch::after {
    display: none;
  }

  .block {
    padding: 0 10px;
  }

  h1 {
    font-size: 50px;
  }
}
